.HAV-web {
    background-color: #ffffff;
    min-height: 100vh;
    width: 1920px;
    position: relative;
    overflow: hidden;
    page-break-after: always;
    page-break-inside: avoid;
}
  
  .HAV-web .overlap {
    height: 958px;
    left: -40px;
    position: relative;
    top: 59px;
    width: 2366px;
  }
  
  .HAV-web .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 64px;
    left: 0;
    position: absolute;
    top: 272px;
  }
  
  .HAV-web .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .HAV-web .group {
    height: 288.25px;
    position: relative;
    width: 287.07px;
  }
  
  .HAV-web .overlap-group {
    height: 291px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 291px;
  }
  
  .HAV-web .vector {
    height: 289px;
    left: 42px;
    position: absolute;
    top: 2px;
    width: 248px;
  }
  
  .HAV-web .rectangle {
    border: 3.52px solid;
    border-color: #000000;
    height: 291px;
    left: 0;
    position: absolute;
    top: 0;
    width: 291px;
  }
  
  .HAV-web .group-wrapper {
    height: 287.08px;
    position: relative;
    width: 287.07px;
  }
  
  .HAV-web .overlap-group-wrapper {
    height: 287px;
    width: 287px;
  }
  
  .HAV-web .img {
    height: 290px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 290px;
  }
  
  .HAV-web .element {
    height: 289.36px !important;
    margin-left: -1.72px !important;
    margin-right: -1.15px !important;
    margin-top: -1.15px !important;
    position: relative !important;
    width: 289.94px !important;
  }

  .HAV-web .square {
     border: 3px solid black;
    /* Adds a black border around the element */
    box-sizing: border-box;
    width: 293.94px !important;
    height: 293.36px !important;
  }
  
  .HAV-web .squarev {
    border: 3px solid black;
   /* Adds a black border around the element */
   box-sizing: border-box;
   width: 251.94px !important;
   height: 293.36px !important;
 }
  .HAV-web .text-wrapper {
    align-self: stretch;
    color: #000000;
    font-family: "Baikal-Light", Helvetica;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 34px;
    position: relative;
    width: 290px;
  }
  
  .HAV-web .div-wrapper {
    height: 282.02px;
    position: relative;
    width: 282.02px;
  }
  
  .HAV-web .rectangle-wrapper {
    background-size: 100% 100%;
    height: 285px;
    left: -1px;
    position: relative;
    top: -2px;
    width: 285px;
  }
  
  .HAV-web .rectangle-2 {
    border: 3.44px solid;
    border-color: #000000;
    height: 285px;
  }
  
  .HAV-web .element-2 {
    height: 284.76px;
    margin-left: -1.72px;
    margin-right: -2.34px;
    margin-top: -2.02px;
    position: relative;
    width: 284.76px;
  }
  
  .HAV-web .group-2 {
    height: 277.13px;
    position: relative;
    width: 277.31px;
  }
  
  .HAV-web .overlap-group-2 {
    height: 281px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 280px;
  }
  
  .HAV-web .vector-2 {
    height: 278px;
    left: 0;
    position: absolute;
    top: 0;
    width: 218px;
  }
  
  .HAV-web .rectangle-3 {
    border: 3.39px solid;
    border-color: #000000;
    height: 280px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 280px;
  }
  
  .HAV-web .element-3 {
    height: 280.7px;
    margin-bottom: -287.7px;
    margin-right: -1413.77px;
    position: relative;
    width: 280.7px;
  }
  
  .HAV-web .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    height: 958px;
    justify-content: space-between;
    left: 106px;
    position: absolute;
    top: 0;

  }
  
  .HAV-web .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    height: 423.44px;
    position: relative;
  }
  
  .HAV-web .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .HAV-web .group-3 {
    height: 26.89px;
    position: relative;
    width: 128.28px;
  }
  
  .HAV-web .text-wrapper-2 {
    color: #000000;
    font-family: "Baikal-Book", Helvetica;
    font-size: 18.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .text-wrapper-3 {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 54.6px;
    position: relative;
    width: 473.01px;
  }
  
  .HAV-web .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    height: 271px;
    justify-content: space-between;
    position: relative;
  }
  
  .HAV-web .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  
  .HAV-web .text-wrapper-4 {
    color: #707070;
    font-family: "Baikal-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.72px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .text-wrapper-5 {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .web-accessibility {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 39px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .vector-3 {
    height: 22px;
    left: 162px;
    position: absolute;
    top: 244px;
    width: 22px;
  }