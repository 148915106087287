.HAV-web {
    background-color: #ffffff;
    height: 1080px;
    overflow: hidden;
    position: relative;
    width: 1920px;
  }
  
  .HAV-web .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 64px;
    height: 288px;
    left: -40px;
    position: absolute;
    top: 366px;
  }
  
  .HAV-web .group-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    margin-bottom: -0.25px;
    position: relative;
  }
  
  .HAV-web .group {
    height: 288.25px;
    position: relative;
    width: 287.07px;
  }
  
  .HAV-web .overlap-group {
    height: 291px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 291px;
  }
  
  .HAV-web .vector {
    height: 289px;
    left: 52px;
    position: absolute;
    top: 2px;
    width: 248px;
  }
  
  .HAV-web .rectangle {
    border: 3px solid;
    border-color: #000000;
    height: 284px;
    left: 0;
    position: absolute;
    top: 0;
    width: 286px;
  }

  .HAV-web .rectangleS {
    border: 3px solid;
    border-color: #000000;
    height: 284px;
    left: 0;
    position: absolute;
    top: 0;
    width: 286px;
  }
  
  .HAV-web .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .HAV-web .div-wrapper {
    height: 287.08px;
    position: relative;
    width: 287.07px;
  }
  
  .HAV-web .overlap-group-wrapper {
    height: 287px;
    width: 287px;
  }
  
  .HAV-web .img {
    height: 290px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 290px;
  }
  
  .HAV-web .element {
    height: 289.36px !important;
    margin-bottom: -1.15px !important;
    margin-left: -1.72px !important;
    margin-right: -1.15px !important;
    margin-top: -1.15px !important;
    position: relative !important;
    width: 289.94px !important;
  }
  
  .HAV-web .group-2 {
    height: 282.02px;
    position: relative;
    width: 282.02px;
  }
  
  .HAV-web .rectangle-wrapper {
    background-size: 100% 100%;
    height: 285px;
    left: -1px;
    position: relative;
    top: -2px;
    width: 285px;
  }
  
  .HAV-web .rectangle-2 {
    border: 3.44px solid;
    border-color: #000000;
    height: 285px;
  }
  
  .HAV-web .element-2 {
    height: 284.76px;
    margin-bottom: -2.04px;
    margin-left: -1.72px;
    margin-right: -2.34px;
    margin-top: -2.02px;
    position: relative;
    width: 284.76px;
  }
  
  .HAV-web .group-3 {
    height: 277.13px;
    position: relative;
    width: 277.31px;
  }
  
  .HAV-web .overlap-group-2 {
    height: 281px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 280px;
  }
  
  .HAV-web .vector-2 {
    height: 278px;
    left: 0;
    position: absolute;
    top: 0;
    width: 218px;
  }
  
  .HAV-web .rectangle-3 {
    border: 3.39px solid;
    border-color: #000000;
    height: 280px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 279px;
  }
  
  .HAV-web .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    margin-bottom: -262.7px;
    position: relative;
  }
  
  .HAV-web .element-3 {
    height: 280.7px;
    margin-right: -1413.77px;
    margin-top: -3409px;
    position: relative;
    width: 280.7px;
  }
  
  .HAV-web .text-wrapper {
    align-self: stretch;
    color: #000000;
    font-family: "Baikal-Light", Helvetica;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 34px;
    position: relative;
  }
  
  .HAV-web .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 89px;
    height: 242px;
    left: 66px;
    position: absolute;
    top: 59px;
  }
  
  .HAV-web .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 111px;
    margin-bottom: -8.89px;
    position: relative;
  }
  
  .HAV-web .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .HAV-web .group-4 {
    height: 26.89px;
    position: relative;
    width: 128.28px;
  }
  
  .HAV-web .text-wrapper-2 {
    color: #000000;
    font-family: "Baikal-Book", Helvetica;
    font-size: 18.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .text-wrapper-3 {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 92px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 89.7px;
    position: relative;
    width: 1119.49px;
  }
  
  .HAV-web .NOTICE-proprietary-wrapper {
    align-items: center;
    display: flex;
    gap: 8px;
    left: 66px;
    position: absolute;
    top: 806px;
    width: 1772px;
  }
  
  .HAV-web .NOTICE-proprietary {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 1772px;
  }
  
  .HAV-web .span {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .HAV-web .text-wrapper-4 {
    font-family: "Baikal-Medium", Helvetica;
    font-weight: 500;
  }
  
  @media print {
    @page {
      size: 307mm 173mm;
    }
  }