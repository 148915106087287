.frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 72px;
    position: relative;
    width: 254px;
  }
  
  .frame .frame-wrapper {
    align-items: flex-start;
    background-color: #ffffff;
    border-color: #dbdbdb;
    border-left-style: solid;
    border-left-width: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 71px;
    justify-content: center;
    padding: 16px 16px 16px 24px;
    position: relative;
    width: 254px;
  }
  
  .frame .div {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: -4.27px;
    margin-top: -4.27px;
    position: relative;
    width: 100%;
  }
  
  .frame .elements-with-an {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #585858;
    display: -webkit-box;
    flex: 1;
    font-family: "Baikal-Book", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 47.54px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
  
  .frame .img {
    flex: 0 0 auto;
    position: relative;
  }
  