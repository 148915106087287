.success {
    color: green;
  }
  
  .error {
    color: red;
  }
  
  @media print {
    @page {
      /* size: 400mm 226mm; */
      size: 400mm 226mm;
      margin: 0;
    }
  
    body {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  
    .print-container {
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      /* transform: scale(1.08); */
      /* transform-origin: top left; */
    }
  
    .page-break {
      page-break-after: always;
      break-after: page;
      width: 100%;
      margin: 0;
      padding: 0;
      top: 0;
      left: 0;
      box-sizing: border-box;
    }
  
    .no-page-break {
      page-break-inside: avoid;
      break-inside: avoid;
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  
    .component {
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  }
  
  @media screen {
    .preview-wrapper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
    }
  
    .preview-content {
      width: 100%;
      height: auto;
      transform: scale(0.65);
      transform-origin: left top;
      margin: 0;
      padding: 0;
      position: relative;
    }
  }
  
  @media print {
    .preview-wrapper,
    .preview-content {
      transform: none;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      position: static;
    }
  }
  