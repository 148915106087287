.HAV-web {

    height: 1080px;
    position: relative;
    width: 1920px;
    max-height:1080px;
    min-height: 1080px;
    max-width: 1920px;
    transform: scale(1);
    transform-origin: top left;
    background-color: #f7f7f7;
  }
  
  .HAV-web .container-data {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    left: 550px;
    position: absolute;
    top: 970px;

  }
  
  .HAV-web .p {
    color: #818181;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 0; /* Adjust this value to reduce space below the element */
    position: relative;
    width: fit-content;

  }
  
  .HAV-web .emulated-desktop {
    color: #818181;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;

    margin-top: 0; /* Adjust this value to reduce space above the element */
  }
  

  
  .HAV-web .title-manual {
    align-items: flex-start;
    display: inline-flex;
    gap: 8px;
    left: 550px;
    font-size: 24px;
    height: 100px;
    padding: 4px 16px 4px 0px;
    position: absolute;
    top: 60px;
  }
  
  .HAV-web .text-wrapper-15 {
    color: #000000;
    font-family: "Baikal-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .these-checks {
    color: #585858;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 60px;
    left: 550px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
    width: 1017px;
  }
  
  .HAV-web .span {
    color: #585858;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  .HAV-web .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    left: 550px;
    position: absolute;
    top: 232px;
  }
  
  .HAV-web .manual-testing {
    color: #585858;
    font-family: "Baikal-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 578.09px;
  }
  
  .HAV-web .text-wrapper-16 {
    color: #585858;
    font-family: "Baikal-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
  }
  
  .HAV-web .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }
  
  .HAV-web .div-wrapper {
    align-items: flex-start;
    background-color: #ffffff;
    border-color: #e2e2e2;
    border-left-style: solid;
    border-left-width: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 4px 8px;
    position: relative;
  }
  
  .HAV-web .frame-8 {
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 4px 8px;
    position: relative;
  }
  
  .HAV-web .aria-attributes {
    color: #585858;
    font-family: "Baikal-Book", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .frame-9 {
    flex: 0 0 auto;
    position: relative;
  }

  .HAV-web .overview-div {
    left: 1100px;
    position: absolute;
    top: 254px;
  }
  
  .HAV-web .overview-url-container {
 
  }

  
  
  .HAV-web .sidebar-url-test-instance {
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  @media print {
    @page {
      size: 307mm 173mm;
    }
  }