.overview-url {
  align-items: flex-start;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #000000;
  display: inline-flex;
  flex-direction: column;
  gap: 33px;
  padding: 32px;
  position: relative;
  width: 650px;
  height: 500px;
}

.overview-url .container-header {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 99px;
  position: relative;

}

.overview-url .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.overview-url .text-wrapper {
  color: #707070;
  font-family: "Baikal-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.72px;
  position: relative;
  width: fit-content;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overview-url .text-wrapper-2 {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overview-url .assets {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 14.35px 14.35px;
  position: relative;
  width: 191px;
}

.overview-url .element {
  height: 45.45px !important;
  margin-bottom: -0.5px !important;
  margin-left: -0.84px !important;
  margin-top: -0.84px !important;
  position: relative !important;
  width: 45.45px !important;
}

.overview-url .img {
  height: 45.75px;
  margin-bottom: -0.82px;
  margin-top: -0.82px;
  position: relative;
  width: 45.75px;
}

.overview-url .instance-119 {
  height: 45.66px !important;
  margin-bottom: -0.77px !important;
  margin-right: -0.45px !important;
  margin-top: -0.77px !important;
  position: relative !important;
  width: 45.66px !important;
}

.overview-url .container-score {
  align-items: flex-start;
  background-color: #f7f7f7;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 26px 32px;
  position: relative;
  width: 535px;
}

.overview-url .score {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 39px;
  position: relative;
}

.overview-url .chart {
  height: 94.08px;
  position: relative;
  width: 96.08px;
}

.overview-url .overlap {
  border-radius: 47.04px;
  height: 94px;
  position: relative;
  width: 94px;
}

.overview-url .group-chart {
  border: 11.18px solid;
  border-color: #ffffff;
  border-radius: 47.04px;
  height: 94px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 94px;
}

.overview-url .overlap-group {
  height: 94px;
  position: relative;
}

.overview-url .ellipse {
  height: 94px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
  width: 94px;
}

.overview-url .ellipse-2 {
  background-color: #f7f7f7;
  border-radius: 35.82px;
  height: 72px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 72px;
}

.overview-url .text-wrapper-3 {
  color: #585858;
  font-family: "Baikal-Book", Helvetica;
  font-size: 26.2px;
  font-weight: 400;
  height: 33px;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 28px;
}

.overview-url .accessibility-score {
  color: #000000;
  font-family: "Baikal-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .container-overview {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 600px;
}

.overview-url .changes {
  align-items: center;
  align-self: stretch;
  background-color: #d32d1f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.overview-url .text-wrapper-4 {
  color: #ffffff;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .text-wrapper-5 {
  color: #ffffff;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .passed {
  align-items: center;
  align-self: stretch;
  background-color: #a1eab1;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.overview-url .text-wrapper-6 {
  color: #000000;
  font-family: "Baikal-Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .text-wrapper-7 {
  color: #000000;
  font-family: "Baikal-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .not-applicable {
  align-items: center;
  align-self: stretch;
  background-color: #dddddd;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.overview-url .text-wrapper-8 {
  color: #313131;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .text-wrapper-9 {
  color: #313131;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.overview-url .need-manual {
  align-items: center;
  align-self: stretch;
  background-color: #ededed;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.overview-url .text-wrapper-10 {
  color: #1e1e1e;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.overview-url .NEED-MANUAL-TEST-IN {
  color: #1e1e1e;
  font-family: "Baikal-SemiBold", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}
