.sidebar-url-test {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 56px;
  position: relative;
  width: 400px;
  background-color: white;
}

.sidebar-url-test .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.sidebar-url-test .div {
  align-items: flex-start;
  display: flex; /* Changed from inline-flex to flex */
  flex-direction: column;
  position: relative;
  max-width: 350px; /* Ensure maximum width for the container */
}

.sidebar-url-test .group {
  height: 26.89px;
  position: relative;
  width: 128.28px;
}

.sidebar-url-test .text-wrapper {
  color: #000000;
  font-family: "Baikal-Book", Helvetica;
  font-size: 18.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.sidebar-url-test .text-wrapper-2 {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 54.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sidebar-url-test .frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.sidebar-url-test .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 34px;
  position: relative;
}

.sidebar-url-test .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.sidebar-url-test .text-wrapper-3 {
  color: #707070;
  font-family: "Baikal-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.72px;
  position: relative;
  width: fit-content;
}
.sidebar-url-test .text-wrapper-4 {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;;
  letter-spacing: 0;
  line-height: 1.5; /* Specific line-height for better readability */
  position: relative;
  overflow: visible; /* Allow text to overflow */
  white-space: normal; /* Allow the text to wrap */
  word-break: break-word; /* Ensure long words do not overflow */
}

.sidebar-url-test .web-accessibility {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 39px;
  position: relative;
  width: fit-content;
}

.sidebar-url-test .vector {
  height: 22px;
  left: 162px;
  position: absolute;
  top: 352px;
  width: 22px;
}

.sidebar-url-test .frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 17.05px 17.05px;
  position: relative;
  width: 330.32px;
}

.sidebar-url-test .element {
  height: 54px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
  position: relative !important;
  width: 54px !important;
}

.sidebar-url-test .rectangle {
  background-color: #f7f7f7;
  height: 52.4px;
  position: relative;
  width: 52.4px;
}

.sidebar-url-test .img {
  height: 54.36px;
  margin-top: -0.98px;
  position: relative;
  width: 54.36px;
}

.sidebar-url-test .instance-119 {
  height: 54.25px !important;
  position: relative !important;
  width: 54.25px !important;
}

.sidebar-url-test .instance-119-instance {
  height: 54.25px !important;
  margin-right: -0.82px !important;
  position: relative !important;
  width: 54.25px !important;
}

.sidebar-url-test .element-2 {
  height: 54.36px;
  position: relative;
  width: 54.36px;
}

.sidebar-url-test .instance-143 {
  height: 54px !important;
  position: relative !important;
  width: 54px !important;
}

.sidebar-url-test .instance-143-instance {
  height: 54px !important;
  margin-bottom: -0.6px !important;
  position: relative !important;
  width: 54px !important;
}
