.frame {
  align-items: flex-start;
  background-color: #ffffff;
  border-color: #e2e2e2;
  border-left-style: solid;
  border-left-width: 6px;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 8px;
  position: relative;
}

.frame .div {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 4px 8px;
  position: relative;
}

.frame .aria-attributes {
  color: #585858;
  font-family: "Baikal-Book", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .img {
  flex: 0 0 auto;
  position: relative;
}
