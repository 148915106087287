/* eslint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .bar {
    @apply h-[40px];
  }

  .logo-button {
    @apply bg-white text-black hover:bg-gray-50 border border-gray-300 rounded-lg;
  }

  .default-button {
    @apply bg-default-violet text-white hover:bg-purple-700 border border-transparent rounded-lg;
  }

  .label-required::after {
    content: " *";
    @apply text-[#6941C6];
  }
}
