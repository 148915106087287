.HAV-web {
  background-color: #ffffff;
  min-height: 100vh;
  width: 1920px;
  position: relative;
  overflow: hidden;
  page-break-after: always;
  page-break-inside: avoid;
  }
  
  .HAV-web .overlap {
    background-color: #f7f7f7;
    height: 1080px;
    left: 755px;
    position: absolute;
    top: 0;
    width: 1165px;
  }
  
  .HAV-web .text-wrapper {
    color: #000000;
    font-family: "Baikal-Light", Helvetica;
    font-size: 24px;
    font-weight: 300;
    left: 242px;
    letter-spacing: 0;
    line-height: 34px;
    position: absolute;
    top: 247px;
    width: 709px;
  }
  
  .HAV-web .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    left: 250px;
    position: absolute;
    top: 421px;
  }
  
  .HAV-web .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 25px;
    position: relative;
  }
  
  .HAV-web .icon-instance-node {
    height: 64px !important;
    margin-left: -1px !important;
    margin-top: -1px !important;
    position: relative !important;
    width: 64px !important;
  }
  
  .HAV-web .frame-2 {
    align-items: flex-start;
    border-color: #000000;
    border-left-style: solid;
    border-left-width: 2px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 0px 29px;
    position: relative;
    width: 615px;
  }
  
  .HAV-web .text-wrapper-2 {
    color: var(--black);
    font-family: "Inter-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27.6px;
    margin-top: -2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .HAV-web .group {
    height: 27px;
    position: relative;
    width: 580px;
  }
  
  .HAV-web .p {
    color: #000000;
    font-family: "Inter-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: 27px;
    position: absolute;
    top: 0;
    width: 578px;
  }
  
  .HAV-web .frame-3 {
    align-items: flex-start;
    border-color: #060606;
    border-left-style: solid;
    border-left-width: 2px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 0px 29px;
    position: relative;
    width: 615px;
  }
  
  .HAV-web .overlap-2 {
    height: 958px;
    left: 66px;
    position: absolute;
    top: 59px;
    width: 572px;
  }
  
  .HAV-web .text-wrapper-3 {
    color: #000000;
    font-family: "Baikal-Light", Helvetica;
    font-size: 24px;
    font-weight: 300;
    left: 2px;
    letter-spacing: 0;
    line-height: 34px;
    position: absolute;
    top: 480px;
    width: 564px;
  }
  
  .HAV-web .text-wrapper-4 {
    color: #000000;
    font-family: "Baikal-Light", Helvetica;
    font-size: 32px;
    font-weight: 300;
    left: 2px;
    letter-spacing: 0;
    line-height: 48px;
    position: absolute;
    top: 258px;
    width: 564px;
  }
  
  .HAV-web .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    height: 958px;
    justify-content: space-between;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .HAV-web .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    height: 423.44px;
    position: relative;
  }
  
  .HAV-web .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .HAV-web .group-2 {
    height: 26.89px;
    position: relative;
    width: 128.28px;
  }
  
  .HAV-web .text-wrapper-5 {
    color: #000000;
    font-family: "Baikal-Book", Helvetica;
    font-size: 18.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .comprehensive {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 54.6px;
    position: relative;
    width: 572.49px;
  }
  
  .HAV-web .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    height: 271px;
    justify-content: space-between;
    position: relative;
  }
  
  .HAV-web .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  
  .HAV-web .text-wrapper-6 {
    color: #707070;
    font-family: "Baikal-Light", Helvetica;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.72px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .text-wrapper-7 {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .web-accessibility {
    color: #000000;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 39px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .vector-4 {
    height: 22px;
    left: 162px;
    position: absolute;
    top: 244px;
    width: 22px;
  }