.HAV-web {
    display: flex; /* Enables flex layout */
    background-color: #f7f7f7;
    height: 1080px;
    position: relative;
    width: 1920px;
    max-height:1080px;
    min-height: 1080px;
    max-width: 1920px;
    position: relative;
  }
  
  .HAV-web .overlap-group {
    background-color: #f7f7f7;
    height: 1080px;
    left: 482px;
    position: absolute;
    top: 0;
    width: 1438px;
  }
  
  .HAV-web .container-cards {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 50px 70px;
    left: 52px;
    position: absolute;
    top: 129px;

  }

  .HAV-web .container-cards-gap-40 {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 70px;
    left: 52px;
    position: absolute;
    top: 129px;

  }
  
  .HAV-web .passed-card {
    color: #8370f7 !important;
  }
  
  .HAV-web .header {
    align-items: flex-start;
    background-color: transparent;
    display: inline-flex;
    flex-direction: column;
    left: 52px;
    position: absolute;
    top: 42px;
  }
  
  .HAV-web .title {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 16px 4px 0px;
    position: relative;
  }
  
  .HAV-web .p {
    color: #000000;
    font-family: "Baikal-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  /* card.css */
.type-passed {
  align-items: flex-start;
  background-color: #a1eab1;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 4px 16px;
  position: relative;
}

.type-error {
  align-items: flex-start;
  background-color: #d32d1f;/* red background for error */
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 4px 16px;
  position: relative;
}

.type-not {
  align-items: flex-start;
  background-color: #DCDCDC;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 4px 16px;
  position: relative;
}

  
  .HAV-web .text-wrapper-status {
    color: #000000;
    font-family: "Baikal-ExpandedMedium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .HAV-web .sidebar-url-test-instance {
    width: 450px;
    flex-shrink: 0; /* Prevents the sidebar from shrinking */
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  @media print {
    @page {
      size: 307mm 173mm;
    }
  }