.card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 192px;
    max-height: 192px;
    position: relative;
    width: 254px; 
    max-width: 254px;
   

  }
  
  .card .container {
    align-items: flex-start;
    background-color: #ffffff;
    border-left-style: solid;
    border-left-width: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
     padding: 16px 16px 12px 24px;
     width: 254px; 
     max-width: 254px;
     height: 192px;
     max-height: 192px;
    position: relative;

  }
  .card .data {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    padding: 5px; /* Add some padding inside the .data container */
    overflow: visible; /* Hide the overflow */
    word-wrap: break-word; /* Ensure words break if needed */
  }
  
  
  .card .text {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 0px;

    justify-content: flex-end;
    position: relative;

  }
  
  .card .aria-attributes {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    color: transparent;
    display: -webkit-box;
    font-family: "Baikal-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 50px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
  
  .card .text-wrapper {
    color: #1e5dff;
  }
  
  .card .span {
    font-size: 14px;
    color: #585858;
    font-weight: 400;
  font-family: "Baikal-Regular", Helvetica;
  width: 187px;
  overflow: visible; /* Allow text to overflow */
  white-space: normal; /* Allow the text to wrap */
  word-break: break-word; /* Ensure long words do not overflow */

  }
  
  .card .div {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    align-self: stretch;
    color: #666666;
    display: -webkit-box;
    font-family: "Baikal-Light", Helvetica;
    font-size: 11px;
    font-weight: 300;
    width: 187px;
    height: 90px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;

  }
  
  .card .url {
    flex: 0 0 auto;
    position: relative;
  }
  
  .card .PASSED {
    border-color: #a1eab1;
  }
  
  .card .ERROR {
    border-color: #d32d1f;
  }
  
  
  .card .NOT {
    border-color: #DCDCDC
  }