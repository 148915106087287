.HAV-web {
  background-color: #ffffff;
  height: 1080px;
  position: relative;
  width: 1920px;
}

.HAV-web .squares {
  height: 958px;
  left: 800px;
  position: absolute;
  top: 59px;
  width: 1100px;

  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  text-align: center; /* Ensures that the content of inline or inline-block elements is centered */
}

.HAV-web .wrap-squares {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  gap: 10px; /* Vertical gap between rows */
  justify-content: space-between;
  align-items: center; /* Center children horizontally */
  width: 800px;
  height: 800px;

}



.HAV-web .square-row {
  display: flex;
  justify-content: space-between; /* This might spread elements too far apart if you want them closely grouped */
  gap: 10px; /* Horizontal gap between elements */
  width: 100%; /* Take full width of parent to allow space-between to work */
}

/* General style for all elements */
.HAV-web .element {
  height: 251px;
  /* Adjusted to match your HTML attribute */
  width: 251px;
  /* Adjusted to match your HTML attribute */
  position: relative;
  display: inline-block;
  /* To display images inline with spacing */
  border: 3px solid black;
  /* Adds a black border around the element */
  box-sizing: border-box;
  /* Includes padding and border in the element's width and height */
}

/* Specific style for .element-pine-tree */
.HAV-web .element-pine-tree {
  height: 248px;
  /* Adjusted to match your HTML attribute */
  width: 248px;
  /* Adjusted to match your HTML attribute */
  position: relative;
  display: inline-block;
  /* To display images inline with spacing */
  border: 3px solid black;
  /* Adds a black border around the element */
  box-sizing: border-box;
  /* Includes padding and border in the element's width and height */
  padding: 15px 15px;
  /* 15px top and bottom, 40px left and right padding */
}

/* Specific style for .element-square-loops */
.HAV-web .element-square-loops {
  height: 248px;
  /* Adjusted to match your HTML attribute */
  width: 248px;
  /* Adjusted to match your HTML attribute */
  position: relative;
  display: inline-block;
  /* To display images inline with spacing */
  border: 3px solid black;
  /* Adds a black border around the element */
  box-sizing: border-box;
  /* Includes padding and border in the element's width and height */
  padding: 20px;
  /* 20px padding on all sides */
}

.HAV-web .frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 958px;
  justify-content: space-between;
  left: 75px;
  position: absolute;
  top: 59px;
  width: 568px;
}

.HAV-web .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.HAV-web .group {
  height: 46.71px;
  position: relative;
  width: 222.84px;
}

.HAV-web .text-wrapper {
  color: #000000;
  font-family: "Baikal-Book", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.HAV-web .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  height: 770px;
  justify-content: flex-end;
  position: relative;
}

.HAV-web .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 35px;
  position: relative;
}

.HAV-web .text-wrapper-2 {
  color: #707070;
  font-family: "Baikal-Light", Helvetica;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.84px;
  position: relative;
  width: fit-content;
}

.HAV-web .text-wrapper-3 {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.HAV-web .web-accessibility {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 54.6px;
  position: relative;
  width: fit-content;
}

.HAV-web .NOTICE-proprietary-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.HAV-web .NOTICE-proprietary {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 568.23px;
}

.HAV-web .span {
  color: #000000;
  font-family: "Baikal-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.HAV-web .text-wrapper-4 {
  font-family: "Baikal-Medium", Helvetica;
  font-weight: 500;
}

.HAV-web .vector {
  height: 32px;
  left: 223px;
  position: absolute;
  top: 368px;
  width: 31px;
}

@media print {
  @page {
    size: 307mm 173mm;
  }
}